import {
  InsuranceProduct,
  Raters,
  RatersEnum,
} from '@bwinsurance/meta-rater-types';
import { UseFormGetValues } from 'react-hook-form';
import { AgentIntakeForm } from '../agent/schema';
import { QuoteGroupWithQuote } from '../models/quoteTypes';
import {
  INSURANCE_PRODUCT_TO_CRM_PRODUCT,
  RaterProduct,
} from '../models/rateProductTypes';

export const findQuoteGroups = ({
  raterProductToSend,
  quoteGroups,
}: {
  raterProductToSend: RaterProduct[];
  quoteGroups: QuoteGroupWithQuote[];
}) => {
  return raterProductToSend.reduce(
    (acc, { rater, product }) => {
      const group = findRaterQuoteGroup(quoteGroups, rater, product);

      if (group?.raterData) {
        acc.successQuoteGroups.push(group);
      }
      if (group?.errors) {
        acc.errorQuoteGroups.push(group);
      }

      return acc;
    },
    {
      successQuoteGroups: [] as QuoteGroupWithQuote[],
      errorQuoteGroups: [] as QuoteGroupWithQuote[],
    }
  );
};

export const findRaterQuoteGroup = (
  quoteGroups: QuoteGroupWithQuote[],
  rater: Raters,
  product?: InsuranceProduct
) => {
  return quoteGroups.find(
    (quoteGroup) =>
      quoteGroup.rater === rater &&
      (!product || quoteGroup.insuranceProduct === product)
  );
};

export const findLatestQuoteGroup = (quoteArray: QuoteGroupWithQuote[]) => {
  return quoteArray.length
    ? quoteArray.reduce((latest, current) =>
        current.updated > latest.updated ? current : latest
      )
    : undefined;
};

export const getSuccessRaterQuoteGroups = (
  quoteGroups: QuoteGroupWithQuote[]
) => {
  const allSuccessQuoteGroups = quoteGroups.filter(
    (quoteGroup) => quoteGroup.status === 'Completed' && quoteGroup.raterData
  );

  const allSuccessQR = allSuccessQuoteGroups.filter(
    (quoteGroup) => quoteGroup.rater === RatersEnum.enum.QuoteRush
  );

  const latestQR = findLatestQuoteGroup(allSuccessQR);

  const latestQRAllProducts = allSuccessQR.filter(
    (quoteGroup) => quoteGroup.raterData?.leadId === latestQR?.raterData?.leadId
  );

  const allSuccessEZ = allSuccessQuoteGroups
    .filter((quoteGroup) => quoteGroup.rater === RatersEnum.enum.EZLynx)
    .reduce(
      (acc, quoteGroup) => {
        const product = quoteGroup.insuranceProduct as keyof typeof acc;
        if (product in acc) {
          acc[product].push(quoteGroup);
        }
        return acc;
      },
      { Home: [], Auto: [] } as {
        [K in 'Home' | 'Auto']: QuoteGroupWithQuote[];
      }
    );

  return [
    ...latestQRAllProducts,
    findLatestQuoteGroup(allSuccessEZ.Home),
    findLatestQuoteGroup(allSuccessEZ.Auto),
  ].filter((quote): quote is QuoteGroupWithQuote => !!quote);
};

export const getCRMProductDisplay = (product: InsuranceProduct) => {
  return INSURANCE_PRODUCT_TO_CRM_PRODUCT[product];
};

export type RaterProductInput = {
  rater: Raters;
  product: InsuranceProduct;
  leadId?: string;
};

export type GroupedRaterProducts = {
  rater: Raters;
  products: INSURANCE_PRODUCT_TO_CRM_PRODUCT[];
  leadId?: string;
};

export const consolidateRaterProducts = (
  raterProducts: RaterProductInput[]
) => {
  return raterProducts.reduce<GroupedRaterProducts[]>(
    (acc, { rater, product, leadId }) => {
      const existingRater = acc.find((r) => r.rater === rater);
      const crmProduct = getCRMProductDisplay(product);
      if (existingRater) {
        existingRater.products = [
          ...new Set([...existingRater.products, crmProduct]),
        ];
      } else {
        acc.push({
          rater,
          products: [crmProduct],
          leadId,
        });
      }
      return acc;
    },
    []
  );
};

export const getSuccessToasts = (quoteGroups: QuoteGroupWithQuote[]) => {
  const raterProducts = quoteGroups.map((quoteGroup) => {
    return {
      rater: quoteGroup.rater as Raters,
      product: quoteGroup.insuranceProduct as InsuranceProduct,
      leadId: quoteGroup.raterData?.leadId,
    };
  });
  const mappedQuoteGroups = consolidateRaterProducts(raterProducts);

  return mappedQuoteGroups.map(({ products, rater, leadId }) => {
    const raterName = rater === RatersEnum.enum.QuoteRush ? 'QuoteRUSH' : rater;
    const leadIdString = leadId ? ` (Lead ID: ${leadId})` : '';
    const productString =
      products.length > 2
        ? `${products.slice(0, -1).join(', ')} and ${products.slice(-1)}`
        : products.join(' and ');
    return {
      type: 'success',
      text: `${productString} sent to ${raterName}${leadIdString}.`,
    };
  });
};

export const getErrorMessages = (quoteGroups: QuoteGroupWithQuote[]) => {
  const failedGroups = quoteGroups.map((quoteGroup) => {
    const raterName =
      quoteGroup.rater === RatersEnum.enum.QuoteRush
        ? 'QuoteRUSH'
        : quoteGroup.rater;
    return {
      rater: raterName,
      product: quoteGroup.insuranceProduct,
      errorName: quoteGroup.errors?.[0]?.name,
      errorMessage: quoteGroup.errors?.[0]?.message,
    };
  });

  const uniqueErrorNames = Array.from(
    new Set(failedGroups.map((group) => group.errorName as ErrorType))
  );

  const messages = uniqueErrorNames.map((errorName) => {
    const matchedGroups = failedGroups.filter(
      (group) => group.errorName === errorName
    );
    const raterNames = Array.from(
      new Set(matchedGroups.map((group) => group.rater))
    );
    const failureMessage = matchedGroups
      .map((group) => group.errorMessage)
      .join(' ');
    return getErrorMessage(errorName, {
      quoteGroupNames: raterNames,
      failureMessage: failureMessage,
    });
  });

  return messages;
};

export type ErrorType =
  | 'AuthenticationError'
  | 'AuthenticationError-EZLynx-UserNotConfiguredInVendor'
  | 'AuthenticationError-EZLynx-UserNotActive'
  | 'ValidationError'
  | 'UnhandledError'
  | 'AlreadySentError'
  | 'InvalidFields';

export const getErrorMessage = (
  errorName?: ErrorType,
  options: {
    quoteGroupNames?: string[];
    failureMessage?: string;
    leadId?: string;
  } = {}
) => {
  const { leadId, quoteGroupNames, failureMessage } = options;
  const leadIdString = leadId ? ` (Lead ID: ${leadId})` : '';
  const nameString = quoteGroupNames?.join(' and ') || '';

  switch (errorName) {
    case 'AuthenticationError-EZLynx-UserNotConfiguredInVendor':
      return 'Your EZLynx account hasn’t been set up to work with Fusion. Please contact Agency Support.';
    case 'AuthenticationError-EZLynx-UserNotActive':
      return 'Your EZLynx account is inactive. Please contact Agency Support.';
    case 'AuthenticationError':
      return `The login credentials we have for ${nameString} are incorrect. Please contact Agency Support.`;
    case 'UnhandledError':
      return quoteGroupNames && quoteGroupNames.length > 1
        ? `${nameString} are having technical difficulties. Try again.`
        : `${nameString} is having technical difficulties. Try again.`;
    case 'ValidationError':
      return failureMessage || 'Something went wrong. Please try again!';
    case 'AlreadySentError':
      return `You've already sent Homeowners. Please make adjustments directly in ${nameString}${leadIdString}.`;
    case 'InvalidFields':
      return 'Some fields are invalid. Please correct the errors and try again.';
    default:
      return 'Something went wrong. Please try again!';
  }
};

export const getRaterButtonErrorMessage = (
  agentName?: string,
  agentId?: string,
  userId?: string
): string => {
  if (!agentId) {
    return 'Please assign an agent to this opportunity and refresh the page in order to send data to a rater.';
  }

  if (agentId !== userId) {
    const assignedAgentName = agentName ?? 'The agent assigned';
    return `${assignedAgentName} hasn't been set up to send data to a rater. Please contact Agency Support.`;
  }

  return "You haven't been set up to send data to a rater. Please contact Agency Support.";
};

export const flatten = (obj: object) => {
  const result: Record<string, string> = {};
  for (const key of Object.keys(obj)) {
    const item = obj[key as keyof object];
    if (typeof item === 'object' && item !== null) {
      const nested = flatten(item);
      for (const nestedKey of Object.keys(nested)) {
        result[`${key}.${nestedKey}`] = nested[nestedKey];
      }
    } else {
      result[key] = item;
    }
  }
  return result;
};

export const checkRequiredFieldsForRaters = (
  getValues: UseFormGetValues<AgentIntakeForm>
): string[] => {
  const baseRequiredFields = {
    'applicant.firstName': 'first name',
    'applicant.lastName': 'last name',
    'applicant.currentAddress.lineOne': 'street address',
    'applicant.currentAddress.stateCode': 'state',
  };

  const getMissingFields = (fields: Record<string, string>) => {
    return Object.entries(fields)
      .filter(([key]) => {
        const value = getValues(key as keyof AgentIntakeForm);
        return (
          typeof value === 'undefined' ||
          (typeof value === 'string' && !value.trim())
        );
      })
      .map(([, value]) => value);
  };

  const missingFields = getMissingFields(baseRequiredFields);

  return missingFields;
};

export const getMissingFieldsStringForRaters = (
  missingFields: string[]
): string => {
  const hasMissing = (fields: string[]) =>
    missingFields.some((field) => fields.includes(field));

  const nameFields = ['first name', 'last name'];
  const addressFields = ['street address', 'state'];
  const propertyAddressFields = ['property street address', 'property state'];

  const missingParts: string[] = [];

  if (hasMissing(nameFields)) missingParts.push("applicant's name");
  if (hasMissing(addressFields))
    missingParts.push("applicant's current address");
  if (hasMissing(propertyAddressFields))
    missingParts.push("property's current address");

  return missingParts.join(' and ');
};

type DirtyFields = Record<string, any>;

export const getChangedFields = (
  data: Record<string, any>,
  dirtyFields: DirtyFields
) => {
  const result: Record<string, any> = {};

  Object.keys(dirtyFields).forEach((key) => {
    if (typeof dirtyFields[key] === 'object' && dirtyFields[key] !== null) {
      const nestedChanged = getChangedFields(data[key], dirtyFields[key]);
      if (Object.keys(nestedChanged).length > 0) {
        result[key] = nestedChanged;
      }
    } else if (dirtyFields[key]) {
      result[key] = data[key];
    }
  });

  return result;
};

export const getCurrentFormattedDateTime = () => {
  const now = new Date();
  const formattedDate = now.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return formattedDate;
};
